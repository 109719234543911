'use strict';

(function() {

  class ResourcesCtrl {
    constructor($scope, $state, Auth, Modal, $uibModal, Resource, usSpinnerService) {
      this.$state = $state;
      this.Resource = Resource;
      this.Auth = Auth;
      this.Modal = Modal;
      this.$uibModal = $uibModal;
      this.usSpinnerService = usSpinnerService;
      this.isAdmin = Auth.isAdmin;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      Auth.getCurrentUser((user)=> {
        if (user && user.organization) {
          this.user = user;
          this.init();
        } else {
          $state.go('login');
        }
      });
      this.resourceType = $scope.resourceType;
      this.resourceId = $scope.resourceId;
      this.loading = false;
      this.totalItems = 1;
      this.currentPage = 1;
      this.pageSize = 15;
      this.resourceDatePicker = {
        date: { startDate: null, endDate: null },
        options: {
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()]
            }
        }
    };
    this.toggleOpen = true;
    }

    init() {
      this.loading = true;
      this.Resource.get({ resourceId: this.resourceId, currentPage: this.currentPage, pageSize: this.pageSize }).$promise
        .then(response => {
          if (response.success && response.data && response.data.length) {
            let resources = response.data;
            this.totalResources = response.count;
            for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
              const resource = resources[resourceIndex];
              let displayImage = this.getImageName(resource);
              resource.image = displayImage;
            }
            this.resources = resources;
            // this.filteredResources = resources;
          } else {
            this.resources = null;
          }
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.resources = null;
        })
    }

    pageChanged() {
      console.log('pageChanged', this.currentPage);
      this.filterResourcesData(this.currentPage);
    }

    filterResourcesData(pageNo) {
      let filterQueryData = {
        startDate: this.resourceDatePicker.date.startDate,
        endDate: this.resourceDatePicker.date.endDate,
        currentPage: pageNo,
        pageSize: this.pageSize
      }
      if (this.filterUsingTag) {
        filterQueryData.filteredTag = this.filterUsingTag;
      }
      if (this.resourceId) {
        filterQueryData.resourceId = this.resourceId;
      }
      console.log('filterQueryData:', filterQueryData);
      this.Resource.filterResources(filterQueryData).$promise
      .then(response => {
          if (response.success && response.data && response.data.length) {
            let resources = response.data;
            this.totalResources = response.count;
            for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
              const resource = resources[resourceIndex];
              let displayImage = this.getImageName(resource);
              resource.image = displayImage;
            }
            this.resources = resources;
          } else {
            this.resources = null;
            this.totalResources = response.count;
          }
          this.currentPage = pageNo;
      })
      .catch(err => {
        console.log('Error filtering resources', err);
      });
  }

    getImageName(resource) {
      if (resource.fileExtension == '.jpg' || resource.fileExtension == '.jpeg' || resource.fileExtension == '.gif' || resource.fileExtension == '.png') {
        return resource.image = 'image';
      } else if (resource.fileExtension == '.pdf') {
        return resource.image = 'pdf';
      } else if (resource.fileExtension == '.doc' || resource.fileExtension == '.docx') {
        return resource.image = 'docs';
      } else if (resource.fileExtension == '.zip') {
        return resource.image = 'zip';
      } else if (resource.fileExtension == '.csv') {
        return resource.image = 'csv';
      } else if (resource.fileExtension == '.xls' || resource.fileExtension == '.xlsx') {
        return resource.image = 'xls';
      } else {
        return resource.image = 'no-image';
      }
    }

    // filterResourcesUsingTags() {
    //   if (this.filterUsingTag) {
    //     this.filteredResources = _.filter(this.resources, resource => {
    //       return resource.tags.some(tag => tag.indexOf(this.filterUsingTag) > -1)
    //     });  
    //   } else {
    //     this.filteredResources = this.resources;
    //   }
    // }

    resetResources() {
      this.filterUsingTag = '';
      this.resourceDatePicker = { date: { startDate: null, endDate: null } };
      // this.filteredResources = this.resources;
      this.currentPage = 1;
      this.init();
    }

    createResource() {
        var modalInstance = this.$uibModal.open({
            size: 'lg',
            templateUrl: 'components/modal/resources/addResourceModal/addResourceModal.html',
            controller: 'AddResourceModalCtrl',
            controllerAs: 'aRC',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              options: {
                title: 'Add Resource',
                resourceData : {
                  resourceId: this.resourceId,
                  resourceType: this.resourceType,
                }
              }
            }
          });
          // modalInstance.result.then(result => {
          //   console.log('close', result);
          //   this.addResource(resource.data)
          // });
          modalInstance.result.then(this.addResource());
    }

    removeResource(resourceId) {
      this.Modal.confirm.delete(() => {
        this.Resource.deleteResource({ resourceId: resourceId }).$promise
          .then(response => {
            this.currentPage = 1;
            this.filterResourcesData(this.currentPage);
            this.toastr.success('Resource removed successfully');
          })
          .catch(err => {
            console.log('Err', err);
            this.toastr.error('Something went wrong. please try again later.');
          })
      })('resource');
    }

    addResource() {
      return resource=> {
        let displayImage = this.getImageName(resource.data);
        resource.data.image = displayImage;
        this.resources.unshift(resource.data);
        // this.resetResources()
      }
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('ResourcesCtrl', ResourcesCtrl);
})();
